import { ClientAutocompleteType, Nullable } from "@/models/CustomTypes";
import IdNameSelect from "@/models/IdNameSelect";

export class RecommendationFormRowModel {
  id: string;
  financialInstrument: IdNameSelect;
  percent: Nullable<number>;
  price: Nullable<string>;

  constructor(
    id: string,
    financialInstrument?: IdNameSelect,
    percent?: Nullable<number>,
    price?: Nullable<string>
  ) {
    this.id = id;
    this.financialInstrument = financialInstrument;
    this.percent = percent;
    this.price = price;
  }
}

export class RecommendationFormModel {
  client: ClientAutocompleteType[];
  decisionDeadlineDate: Nullable<string>;
  decisionDeadlineTime: Nullable<string>;
  additionalText: Nullable<string>;
  rows: RecommendationFormRowModel[];

  constructor(
    client?: ClientAutocompleteType[],
    decisionDeadlineDate?: Nullable<string>,
    decisionDeadlineTime?: Nullable<string>,
    additionalText?: Nullable<string>,
    rows?: RecommendationFormRowModel[]
  ) {
    this.client = client;
    this.decisionDeadlineDate = decisionDeadlineDate;
    this.decisionDeadlineTime = decisionDeadlineTime;
    this.additionalText = additionalText;
    this.rows = rows;
  }
}
