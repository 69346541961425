








































































import Vue from "vue";
import Component from "vue-class-component";
import CreateRecommendationForm from "@/context/recommendations/components/recommendations/forms/CreateRecommendationForm.vue";
import {
  RecommendationFormModel,
  RecommendationFormRowModel
} from "@/context/recommendations/models/recommendations/RecommendationFormModel2";
import defaultRecommendationObject from "@/context/recommendations/utils/default-recommendation-object";
import { ClientAutocompleteType, FormComponent, VForm } from "@/models/CustomTypes";
import { ToastOptions } from "vue-toasted";
import {
  CreateRecommendationCmd,
  CreateRecommendationRowCmd
} from '@/context/recommendations/models/recommendations/CreateRecommendationCmd';
import { RecommendationApi } from '@/api/RecommendationApi';

@Component({
  components: {
    CreateRecommendationForm
  }
})
export default class RecommendationsCreatePage extends Vue {
  models: Array<RecommendationFormModel> = [defaultRecommendationObject()];
  currentPanel = 0;

  addNew(): void {
    this.models.push(defaultRecommendationObject());
    this.currentPanel = this.models.length - 1;
  }

  remove(index: number): void {
    this.models.splice(index, 1);
    this.currentPanel = this.models.length - 1;
  }

  async createRecommendations() {
    try {
      const createCmd: CreateRecommendationCmd[] = this.models.map(
        (model: RecommendationFormModel) => {
          return new CreateRecommendationCmd(
            model.client,
            model.decisionDeadlineDate,
            model.decisionDeadlineTime,
            model.additionalText,
            model.rows.map((row: RecommendationFormRowModel) => {
              return new CreateRecommendationRowCmd(row.financialInstrument.name, row.percent, row.price)
            })
          );
        }
      );
      const response = await RecommendationApi.create(createCmd);
      this.models = [defaultRecommendationObject()];
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.success("Rekomendacje zostały zapisane.", options);
      // this.$router.push("/recommendations/list");
    } catch (e) {
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.error("Wystąpił błąd przy zapisie rekomendacji.", options);
    }
  }

  showErrorDialog() {
    let options: ToastOptions = {
      type: "error",
      icon: "mdi-alert-circle",
      duration: 5 * 1000,
      position: "top-right"
    };
    this.$toasted.error(
      "Formularz zawiera błędy uniemożliwiające utworzenie rekomendacji. Sprawdź formularz i spróbuj ponownie.",
      options
    );
  }

  submit() {
    let validationResult = true;
    const refs = Object.keys(this.$refs);
    for (let i = 0; i < refs.length; i++) {
      const refArr: FormComponent[] = this.$refs[refs[i]] as FormComponent[];
      const result = refArr[0].submit();
      validationResult = validationResult && result;
    }
    if (validationResult) {
      this.createRecommendations();
    } else {
      this.showErrorDialog();
    }
  }

  reset() {
    const refs = Object.keys(this.$refs);
    for (let i = 0; i < refs.length; i++) {
      const refArr: VForm[] = this.$refs[refs[i]] as VForm[];
      refArr[0].reset();
    }
  }

  formatHeader(model: RecommendationFormModel) {
    let header = "";
    if (model.client.length > 0) {
      header += model.client.map((c: ClientAutocompleteType) => c.company + ", ");
      header += " | ";
    }
    if (model.decisionDeadlineDate) {
      header += model.decisionDeadlineDate + " ";
    }
    if (model.decisionDeadlineTime) {
      header += model.decisionDeadlineTime;
    }
    return header;
  }

  createUUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
  }

  addNewRow(index) {
    if (this.models[index]) {
      this.models[index].rows.push(
        new RecommendationFormRowModel(this.createUUID(), null, null, null)
      );
    }
  }

  removeRow(index, id) {
    if (this.models[index]) {
      const indexToRemove = this.models[index].rows.map(v => v.id).indexOf(id);
      this.models[index].rows.splice(indexToRemove, 1);
    }
  }
}
