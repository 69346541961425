import { RecommendationFormModel } from "@/context/recommendations/models/recommendations/RecommendationFormModel2";
import { PriceApprovalFormModel } from "@/context/price-approval/models/PriceApprovalFormModel";
//
// export  function defaultRecommendationObjectOld(): RecommendationFormModel {
//   return {
//     client: [],
//     agreement: null,
//     financialInstrument: null,
//     percent: null,
//     price: null,
//     decisionDeadlineDate: null,
//     decisionDeadlineTime: null,
//     additionalText: null
//   };
// }

export default function defaultRecommendationObject(): RecommendationFormModel {
  return {
    client: [],
    rows: [],
    decisionDeadlineDate: null,
    decisionDeadlineTime: null,
    additionalText: null
  };
}

export function defaultPriceApprovalObject(): PriceApprovalFormModel {
  return {
    year: null,
    wallets: [],
    periodTypes: []
  };
}
