














































































import Vue from "vue";
import { VModel } from "vue-property-decorator";
import AgreementAutocomplete from "@/components/form-controls/AgreementAutocomplete.vue";
import FinancialInstrumentAutocomplete from "@/components/form-controls/FinancialInstrumentAutocomplete.vue";
import Component from "vue-class-component";
import DateInput from "@/components/form-controls/DateInput.vue";
import TimeInput from "@/components/form-controls/TimeInput.vue";
import {
  RecommendationFormModel,
  RecommendationFormRowModel
} from "@/context/recommendations/models/recommendations/RecommendationFormModel2";
import defaultRecommendationObject from "@/context/recommendations/utils/default-recommendation-object";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { required } from "@/context/recommendations/utils/validations";
import { ValidationFunctionType, VForm } from "@/models/CustomTypes";
import _ from "lodash";
import { littleClientsGroup } from "@/context/recommendations/models/recommendations/LittleClientsGroup";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

@Component({
  components: {
    DateInput,
    TimeInput,
    AgreementAutocomplete,
    FinancialInstrumentAutocomplete,
    ClientAutocomplete,
    TiptapVuetify
  }
})
export default class CreateRecommendationForm extends Vue {
  @VModel({
    default: defaultRecommendationObject(),
    type: Object
  })
  model: RecommendationFormModel;
  clientRules: [ValidationFunctionType] = [required];
  agreementRules: [ValidationFunctionType] = [required];
  financialInstrumentRules: [ValidationFunctionType] = [required];
  percentRules: [ValidationFunctionType] = [required];
  priceRules: [ValidationFunctionType] = [required];
  responseDeadlineRules: [ValidationFunctionType] = [required];
  additionalTextRules: [ValidationFunctionType] = [required];
  extensions = [
    History,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3]
        }
      }
    ],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak
  ];
  get form(): VForm {
    return this.$refs.form as VForm;
  }

  selectLittleClientsGroup() {
    const tmp: RecommendationFormModel = _.clone(this.model);
    tmp.client = littleClientsGroup();
    this.$emit("input", tmp);
  }

  submit(): boolean {
    return this.form.validate();
  }

  reset() {
    this.form.reset();
  }
  removeRow(id) {
    this.$emit("remove-row", id);
  }
}
