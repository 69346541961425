import { ValidationFunctionType } from "@/models/CustomTypes";

export const required: ValidationFunctionType = (v: Record<string, unknown>) =>
  !!v || "Pole jest wymagane.";

export const emailRule: (v: string) => true | string = (v: string) =>
  /.+@.+\..+/.test(v) || "Nieprawidłowy adres e-mail.";

export const correctNip: (v: string) => true | string = (v: string) => {
  const nipWithoutDashes = v ? v.toString().replace(/-/g, "") : "";
  const reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) == false) {
    return "Nieprawidłowy numer NIP.";
  } else {
    const digits = ("" + nipWithoutDashes).split("");
    const checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;
    return parseInt(digits[9]) == checksum ? true : "Nieprawidłowy numer NIP.";
  }
};

export function minLength(minLength: number) {
  return function(v: string) {
    const input: string = v ? v.toString() : "";
    return input.length >= minLength ? true : `Minimalna liczba znaków: ${minLength}.`;
  };
}
