import { ClientAutocompleteType } from "@/models/CustomTypes";

export const littleClientsGroup = (): ClientAutocompleteType[] => [
  {
    id: 181,
    company: "ABX Sp. z o.o."
  },
  {
    id: 169,
    company: "Apart Sp. z o.o."
  },
  {
    id: 182,
    company: "Calz Polska Sp. z o.o."
  },
  {
    id: 183,
    company: "Camaieu Polska Sp. z o.o."
  },
  {
    id: 158,
    company: "Deichmann-Obuwie Sp. z o.o."
  },
  {
    id: 170,
    company: "Douglas Polska Sp. z o.o."
  },
  {
    id: 199,
    company: "Giacomo Conti"
  },
  {
    id: 171,
    company: "Go Sport Polska Sp.z o.o."
  },
  {
    id: 186,
    company: "Hotel Haffner Sp.z o.o."
  },
  {
    id: 172,
    company: "Inmedio Sp.z o.o."
  },
  {
    id: 189,
    company: "Lagardere Travel Retail Sp.z o.o."
  },
  {
    id: 173,
    company: "Ordipol Sp.z o.o."
  },
  {
    id: 174,
    company: "R2 Investment Sp.z o.o.Spółka Komandytowo - Akcyjna"
  },
  {
    id: 188,
    company: "Kan Sp.z o.o."
  },
  {
    id: 187,
    company: "JOART S.A."
  },
  {
    id: 190,
    company: "PROVALLIANCE POLAND SP.Z O.O."
  },
  {
    id: 191,
    company: "R2 Center Sp.z o.o."
  },
  {
    id: 176,
    company: "Towarzystwo Ubezpieczeń Wzajemnych 'TUW'"
  },
  {
    id: 209,
    company: "GUESS POLAND SP.Z O.O"
  },
  {
    id: 214,
    company: "Korporacja Logistyczna Mag Dystrybucja Sp.z o.o.Sp.k."
  },
  {
    id: 213,
    company: "C & A Polska Sp.z o.o."
  },
  {
    id: 201,
    company: 'P.H.U. "IREX-POL" Ługowski Ireneusz'
  }
];
