import { ClientAutocompleteType, Nullable } from "@/models/CustomTypes";

export class CreateRecommendationRowCmd {
  financial_instrument: Nullable<string>;
  percent: Nullable<number>;
  price: Nullable<string>;

  constructor(
    financial_instrument: Nullable<string>,
    percent: Nullable<number>,
    price: Nullable<string>
  ) {
    this.financial_instrument = financial_instrument;
    this.percent = percent;
    this.price = price;
  }
}
export class CreateRecommendationCmd {
  client?: ClientAutocompleteType[];
  decision_deadline: Nullable<string>;
  additional_text: Nullable<string>;
  rows: Nullable<CreateRecommendationRowCmd[]>;

  constructor(
    client: ClientAutocompleteType[],
    decisionDeadlineDate: Nullable<string>,
    decisionDeadlineTime: Nullable<string>,
    additionalText: Nullable<string>,
    rows: Nullable<CreateRecommendationRowCmd[]>
  ) {
    this.client = client;
    this.decision_deadline = decisionDeadlineDate + " " + decisionDeadlineTime + ":00";
    this.additional_text = additionalText;
    this.rows = rows;
  }
}
