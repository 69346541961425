



















import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { AgreementApi } from "@/api/AgreementApi";
import { value } from "@/models/CustomTypes";

@Component
export default class AgreementAutocomplete extends Vue {
  @Prop({ default: "Umowa" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: [] }) rules: [];
  @VModel({ type: [Number, Object] }) localValue: value;
  items: [] = [];

  async loadData() {
    const url = `${AgreementApi.baseUrl}${this.queryParam}`;
    const { data } = await this.$http.get(url);
    this.items = data;
  }

  @Watch("queryParam")
  onQueryParamChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }

  async created() {
    if (this.disabled) {
      await this.loadData();
    }
  }
}
