import { axiosInstance as axios } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { CreateRecommendationCmd } from "@/context/recommendations/models/recommendations/CreateRecommendationCmd";

// export async function create(command: CreateRecommendationRecipientCmd) {
//
//     const response = await axiosConfig.post(baserUrl + "create", command);
//
//     return response;
// }
//
// export async function list(query: string) {
//
//     const response = await axiosConfig.get(baserUrl + "?" + query);
//
//     return response;
// }
//
// export async function remove(id: number) {
//
//     const response = await axiosConfig.delete(baserUrl + "delete/" + id);
//
//     return response;
// }
//
// export async function update(command: UpdateRecommendationRecipientCmd) {
//
//     const response = await axiosConfig.put(baserUrl + "update/"+ command.id, command);
//
//     return response;
// }
//
// export async function get(id: number) {
//
//     const response = await axiosConfig.get(baserUrl + id);
//
//     return response;
// }

export class RecommendationApi {
  static baseUrl: string = "/api/recommendations/";

  public static async list(query: string) {
    const response = await axios.get(RecommendationApi.baseUrl + "list?" + query);

    return response;
  }

  public static async create(data: CreateRecommendationCmd[]): Promise<AxiosResponse> {
    return await axios.post(RecommendationApi.baseUrl, data);
  }
}
